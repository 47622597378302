import { ComposerNumberFormatting } from 'vue-i18n'
import { NumberFormat } from './numberFormat.types'

export const parseNumberOrThrow = (value: unknown): number => {
  const valueAsNumber = parseNumberOrNull(value)

  if (valueAsNumber === null) {
    throw new Error('Input emitted non numeric value!')
  }

  return valueAsNumber
}

export const parseNumberOrNull = (value: unknown): number | null => {
  const valueAsNumber = parseNumber(value)

  return Number.isNaN(valueAsNumber) ? null : valueAsNumber
}

export const parseNumber = (value: unknown) =>
  Number.parseFloat(String(value)?.replace(',', '.'))

export const divideSafe = (
  dividend: number,
  divisor: number
): number | null => {
  if (divisor === 0) {
    return null
  }
  return dividend / divisor
}

export const getPercentFromRatio = (ratio: number): number => ratio * 100
export const getRatioFromPercent = (percent: number): number => percent / 100

export const getPercentStringFromRatio = (
  n: ComposerNumberFormatting,
  ratio: number
) => {
  return n(getPercentFromRatio(ratio), NumberFormat.Percentage) + '%'
}

export const toNegative = (value: number) =>
  value === 0 ? 0 : -Math.abs(value)

export const getPercentFromTotal = (number: number, total: number) => {
  return (divideSafe(number, total) ?? 0) * 100
}
